import React, { useEffect, useState } from "react";
import { Link, graphql } from "gatsby";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";

import SEO from "../components/SEO";
import styled from "styled-components";
import { Modal } from "react-bootstrap";

const ServiceStyles = styled.div`
  color: #fff;
  text-align: center;
  h1 {
    width: 100%;
    font-size: 10rem;
    height: 100px;
  }
  p {
    font-size: 2.5rem;
  }

  a {
    text-decoration: none;
    color: var(--white);
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 15px auto;
    width: 90%;
    .gatsby-image-wrapper {
      margin: 8px;
      max-height: 50vh !important;
      max-width: 30vw !important;
      flex-grow: 1;
    }
  }
  .btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    div {
      background-color: #00be0a; 
      border-radius: 32px;
      width: 240px;
      height: 51px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      &:hover {
        mix-blend-mode: screen;
        background-color: #575756;
      }
      a {
        margin-top: 0.5rem;
      }
    }
  }
  .cares {
    position: relative;
    margin: 3rem 0;
    height: 60px;
    display: flex;
    align-content: center;
    justify-content: center;
    div {
      text-align: center;
      span {
        color: var(--white);
        cursor: pointer;
      }
    }
  }

  @media (max-width: 1100px) {
    h1 {
      span {
        font-size: 2.5rem;
      }
    }
    p {
      font-size: 1.75rem;
    }
    .container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 10px auto;
      width: 100%;
      .gatsby-image-wrapper {
        margin: 4px;
        max-height: 80vh !important;
        max-width: 80vw !important;
        flex-grow: 1;
      }
    }
  }
  .cares {
    div {
      /*width: 100vw;
      margin-left: -15%;*/
      text-align: center;
    }
  }
`;

function CaresImg({ onHide }) {
  return (
    <div className="wrap-im" onClick={onHide}>
      <StaticImage
        src="../assets/images/cares.jpeg"
        className=""
        placeholder="blurred"
        style={{ maxWidth: "320px" }}
        width={320}
        alt="cares"
      />
    </div>
  );
}

function Title({ src }) {
  if (src === "../assets/images/FONDO3.jpg") {
    return (
      <div className="wrap-im">
        <StaticImage
          src="../assets/images/LOGO-MINI-BOSQUES.png"
          className=""
          placeholder="none"
          style={{ maxWidth: "280px" }}
          width={280}
          alt="logo"
        />
      </div>
    );
  } else {
    return (
      <div className="wrap-im">
        <StaticImage
          src="../assets/images/LOGO-LA-COQUETA.png"
          className=""
          placeholder="none"
          style={{ maxWidth: "280px" }}
          width={280}
          alt="logo"
        />
      </div>
    );
  }
}

function HeroBosque({ children }) {
  return (
    <div style={{ display: "grid" }}>
      <StaticImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          maxHeight: 1440,
          height: "100vh",
          width: "100vw",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 0,
        }}
        layout="fullWidth"
        // You can optionally force an aspect ratio for the generated image
        //aspectRatio={3 / 1.5}
        // This is a presentational image, so the alt should be an empty string
        alt=""
        src="../assets/images/FONDO3.jpg"
        formats={["auto", "webp", "avif"]}
      />
      <div
        style={{
          gridArea: "1/1",
          filter: "opacity(0.4)",
          background: "#062d4c",
        }}
      ></div>
      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
          // This centers the other elements inside the hero component
          //placeItems: "center",
          display: "grid",
          // justifyContent: "center",
          //justifyItems: "center",
          //alignContent: "center",
          //alignItems: "center",
          padding: "15% 10%",
        }}
      >
        {children}
      </div>
    </div>
  );
}
function HeroLaCoqueta({ children, src }) {
  return (
    <div style={{ display: "grid" }}>
      <StaticImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          maxHeight: 1440,
          height: "100vh",
          width: "100vw",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 0,
        }}
        layout="fullWidth"
        // You can optionally force an aspect ratio for the generated image
        //aspectRatio={3 / 1.5}
        // This is a presentational image, so the alt should be an empty string
        alt=""
        src="../assets/images/LACOQUETA/LAC7.jpg"
        formats={["auto", "webp", "avif"]}
      />
      <div
        style={{
          gridArea: "1/1",
          filter: "opacity(0.5)",
          background: "#062d4c",
        }}
      ></div>
      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
          // This centers the other elements inside the hero component
          //placeItems: "center",
          display: "grid",
          // justifyContent: "center",
          //justifyItems: "center",
          //alignContent: "center",
          //alignItems: "center",
          padding: "15% 10%",
        }}
      >
        {children}
      </div>
    </div>
  );
}

const toTop = () => {
  if (document) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
};

export default function SlicemasterPage({ data, pageContext }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  //console.log(data, pageContext);
  const images = data.allImageSharp.nodes.map((n) =>
    getImage(n.gatsbyImageData)
  );

  if (pageContext.img === "../assets/images/FONDO3.jpg") {
    return (
      <ServiceStyles>
        <SEO title={pageContext.name} />
        <HeroBosque>
          <Modal
            show={modalIsOpen}
            onHide={() => setModalIsOpen(false)}
            centered
          >
            <Modal.Body>
              <CaresImg onHide={() => setModalIsOpen(false)} />
            </Modal.Body>
          </Modal>
          <Link to={"/"}>
            <Title src={pageContext.img} />
          </Link>
          <div className="btn">
            <div>
              <a
                href={`https://www.instagram.com/${pageContext.instagram}`}
                alt="instagram"
              >
                INSTAGRAM
              </a>
            </div>
          </div>

          {/*pageContext.name === "biobosques" && (
            <div className="cares btn" onClick={() => setModalIsOpen(true)}>
              <div onClick={() => setModalIsOpen(true)}>
                <span>CUIDADOS</span>
              </div>
            </div>
          )*/ }
           <div className="cares btn" onClick={() => setModalIsOpen(true)}>
              <div onClick={() => setModalIsOpen(true)}>
                <span>CUIDADOS</span>
              </div>
            </div>
          <div className="btn">
            <div>
              <a href="https://wa.me/c/573216168239">
                CATÁLOGO
              </a>
            </div>
            
          </div>
          <p>{pageContext.description}</p>
          <div className="container">
            {images && images.map((i) => <GatsbyImage image={i} alt={""} key={i.id} />)}
          </div>

          <div className=" btn" onClick={toTop}>
            <span>VOLVER AL INICIO</span>
          </div>
        </HeroBosque>
      </ServiceStyles>
    );
  } else {
    return (
      <ServiceStyles>
        <SEO title={pageContext.name} />
        <HeroLaCoqueta>
          <Link to={"/"}>
            <Title src={pageContext.img} />
          </Link>
          <div className="btn">
            <div>
              <a
                href={`https://www.instagram.com/${pageContext.instagram}`}
                alt="instagram"
              >
                INSTAGRAM
              </a>
            </div>
          </div>
          <div className="btn"> 
            <div>
              <a href="https://wa.me/c/573216168239">
                CATÁLOGO
              </a>
            </div> 
          </div>
          <p>{pageContext.description}</p>
          <div className="container">
            {images && images.map((i) => <GatsbyImage image={i} alt={""} key={i.id} />)}
          </div>
          
          <div className=" btn" onClick={toTop}>
            <span>VOLVER AL INICIO</span>
          </div>
        </HeroLaCoqueta>
      </ServiceStyles>
    );
  }
}

export const MyImgFragment = graphql`
  fragment MyImgFragment on ImageSharp {
    gatsbyImageData(
      layout: FIXED
      placeholder: BLURRED
      formats: [AUTO, WEBP]
      height: 720
    )
  }
`;

export const pageQuery = graphql`
  query ($regx: String!) {
    allImageSharp(filter: { original: { src: { regex: $regx } } }) {
      nodes {
        id
        ...MyImgFragment
      }
    }
  }
`;
